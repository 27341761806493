import React from "react";
import { Layout, Stack, Main } from "@layout";
import PageTitle from "@components/PageTitle";
import Divider from "@components/Divider";
import Seo from "@widgets/Seo";
import "./pages.css";

const Contact = (props) => {
  return (
    <Layout {...props}>
      <Seo title="3air Contact Information" />
      <Divider />
      <Stack effectProps={{ effect: "fadeInDown" }}>
        <PageTitle header="3air Contact Information" />
      </Stack>
      <Stack>
        <Main>
          <h1>Get in touch with us</h1>
          <p>We’re happy to hear from you!</p>
          <p>
            For technical support and questions related to your 3air account,
            tokens, payments, and withdrawals, please send us an email at{" "}
            <a href="mailto:support@3air.io">support@3air.io</a>.
          </p>
          <p>
            For general information and other inquiries, feel free to reach out
            at <a href="mailto:info@3air.io">info@3air.io</a>.
          </p>
        </Main>
      </Stack>
    </Layout>
  );
};

export default Contact;
